<template>
  <v-container fluid>
    <v-row>
      <v-col cols="7">
        <base-material-card
          color="primary"
          icon="mdi-eye-outline"
          inline
          class="px-5 py-3"
        >
          <template v-slot:after-heading>
            <div class="display-1 font-weight-light">
              Visualizar Beacon no Mapa
            </div>
          </template>
          <v-row class="mt-5">
            <v-col cols="12">
              <v-progress-circular
                v-if="loadingDetalhesBeacon"
                size="15"
                indeterminate
                color="rgba(0, 0, 0, 0.87)"
              />
              <div id="mapa-beacon"></div>
            </v-col>
          </v-row>
        </base-material-card>
        <base-material-card
          color="primary"
          icon="mdi-timer-marker-outline"
          inline
          class="px-5 py-3"
        >
          <template v-slot:after-heading>
            <div class="display-1 font-weight-light">
              Histórico de localização do Beacon
            </div>
          </template>
          <v-row class="mt-5">
            <v-col cols="12">
              <v-progress-circular
                v-if="loadingDetalhesBeacon"
                size="15"
                indeterminate
                color="rgba(0, 0, 0, 0.87)"
              />
              <div v-else>
                <historico-localizacao-beacon :beacon="detalhesBeacon" />
              </div>
            </v-col>
          </v-row>
        </base-material-card>
      </v-col>
      <v-col cols="5">
        <base-material-card
          color="primary"
          icon="mdi-wifi-marker"
          inline
          class="px-5 py-3"
        >
          <template v-slot:after-heading>
            <div class="display-1 font-weight-light">Detalhes do Beacon</div>
          </template>
          <v-row>
            <v-col cols="12">
              <v-progress-circular
                v-if="loadingDetalhesBeacon"
                size="15"
                indeterminate
                color="rgba(0, 0, 0, 0.87)"
              />
              <div v-else>
                <v-simple-table dense>
                  <template v-slot:default>
                    <tbody>
                      <tr>
                        <td>
                          <strong>MAC Address do Beacon:</strong>
                        </td>
                        <td>
                          {{ detalhesBeacon.mac_address_beacon | toUpperCase }}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Gateway do Beacon:</strong>
                        </td>
                        <td>
                          {{ detalhesBeacon.nome_gateway | toUpperCase }}
                          ({{
                            detalhesBeacon.mac_address_gateway
                              | formatMacAddress
                          }})
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Subestação:</strong>
                        </td>
                        <td>
                          {{ detalhesBeacon.codigo_subestacao }} -
                          {{ detalhesBeacon.nome_subestacao }}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Equipamento na BDGD:</strong>
                        </td>
                        <td>
                          {{ detalhesBeacon.entidade | toUpperCase }} de COD_ID
                          = {{ detalhesBeacon.cod_id }}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Cadastrado por:</strong>
                        </td>
                        <td>
                          {{ detalhesBeacon.criado_por }} em
                          {{ detalhesBeacon.criado_em | parseToDateTimeBR }}
                        </td>
                      </tr>
                      <tr>
                        <td
                          :style="{
                            color:
                              detalhesBeacon.segundos_decorridos_desde_ultima_localizacao_beacon >
                              tempoLimiteSemAtualizacaoLocalizacaoBeaconsEmSegundos
                                ? 'red'
                                : '#000'
                          }"
                        >
                          <strong>Última localização em:</strong>
                        </td>
                        <td
                          :style="{
                            color:
                              detalhesBeacon.segundos_decorridos_desde_ultima_localizacao_beacon >
                              tempoLimiteSemAtualizacaoLocalizacaoBeaconsEmSegundos
                                ? 'red'
                                : '#000'
                          }"
                        >
                          {{
                            detalhesBeacon.ultima_localizacao_beacon_em
                              | parseToDateTimeBR
                          }}
                          <span
                            v-if="
                              detalhesBeacon.segundos_decorridos_desde_ultima_localizacao_beacon >
                              0
                            "
                          >
                            ({{
                              detalhesBeacon.segundos_decorridos_desde_ultima_localizacao_beacon
                                | formatDurationTxt
                            }})
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </div>
            </v-col>
          </v-row>
        </base-material-card>
        <base-material-card
          color="primary"
          icon="mdi-connection"
          inline
          class="px-5 py-3"
        >
          <template v-slot:after-heading>
            <div class="display-1 font-weight-light">
              Detalhes do Equipamento
              {{ detalhesBeacon.entidade | toUpperCase }}
            </div>
          </template>
          <v-row>
            <v-col cols="12">
              <v-progress-circular
                v-if="loadingDetalhesEquipamento"
                size="15"
                indeterminate
                color="rgba(0, 0, 0, 0.87)"
              />
              <div v-else>
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  class="ml-auto mb-4 break-search bs-tabelas"
                  label="Procurar"
                  hide-details
                  single-line
                />
                <v-simple-table dense>
                  <template v-slot:default>
                    <tbody>
                      <tr
                        v-for="item in dadosEquipamento"
                        :key="item.coluna"
                      >
                        <td>
                          <strong>{{ item.coluna | toUpperCase }}:</strong>
                        </td>
                        <td>{{ item.valor }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </div>
            </v-col>
          </v-row>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import L from 'leaflet';
import MapboxConfig from '@/config/MapboxConfig';
import mapbox from '@/config/Mapbox';
import BeaconsService from '@/services/monitoramento-em-tempo-real/BeaconsService';
import EntidadesBdgdService from '@/services/EntidadesBdgdService';
import browserPrintMixins from '@/mixins/leaflet/browserPrintMixins';
import HistoricoLocalizacaoBeacon from '@/components/monitoramento-em-tempo-real/HistoricoLocalizacaoBeacon';
import '@/plugins/leaflet-icon-glyph-v2';

const mapboxConfig = new MapboxConfig(mapbox.access_token, mapbox.style);

export default {
  name: 'VisualizarMapa',
  components: { HistoricoLocalizacaoBeacon },
  mixins: [browserPrintMixins],
  data: () => ({
    map: null,
    search: null,
    loadingDetalhesBeacon: false,
    loadingDetalhesEquipamento: false,
    detalhesBeacon: {},
    detalhesEquipamento: {},
    tempoAtualizacaoLocalizacaoBeaconsEmSegundos: 60
  }),
  mounted() {
    this.getDetalhesBeacon();
  },
  computed: {
    bdgdVersao() {
      return this.$store.getters.getUserSelectedCompanyBdgdVersion;
    },
    dadosEquipamento() {
      const dadosEquipamento = [];
      const colunasEquipamento = this.detalhesEquipamento.colunas;
      if (
        colunasEquipamento &&
        this.detalhesEquipamento.dados &&
        this.detalhesEquipamento.dados[0]
      ) {
        colunasEquipamento.forEach((coluna) => {
          const valorColuna = this.detalhesEquipamento.dados[0][coluna];
          if (valorColuna === null) return;
          dadosEquipamento.push({
            coluna,
            valor: valorColuna
          });
        });
      }
      if (this.search !== null) {
        return dadosEquipamento.filter(
          (item) =>
            item.coluna.toUpperCase().includes(this.search.toUpperCase()) ||
            String(item.valor).toUpperCase().includes(this.search.toUpperCase())
        );
      }
      return dadosEquipamento;
    },
    tempoLimiteSemAtualizacaoLocalizacaoBeaconsEmSegundos() {
      return this.tempoAtualizacaoLocalizacaoBeaconsEmSegundos * 10;
    }
  },
  methods: {
    getDetalhesBeacon() {
      this.loadingDetalhesBeacon = true;
      this.loadingDetalhesEquipamento = true;
      BeaconsService.getBeaconById(this.$route.params.id)
        .then(({ data }) => {
          this.detalhesBeacon = data;
          this.getDetalhesEquipamento(
            this.bdgdVersao.id,
            this.detalhesBeacon.entidade,
            this.detalhesBeacon.cod_id
          );
        })
        .catch((err) => {
          console.log('Erro: ', err);
          this.$toast.error('Erro ao recuperar os dados do beacon.', '', {
            position: 'topRight'
          });
        })
        .finally(() => (this.loadingDetalhesBeacon = false));
    },
    getDetalhesEquipamento(bdgdVersaoId, entidade, codId) {
      this.loadingDetalhesEquipamento = true;
      const filtros = [
        {
          campo: 'cod_id',
          funcao: '=',
          valor: codId,
          type: 'character varying'
        }
      ];
      EntidadesBdgdService.getDadosEntidade(entidade, bdgdVersaoId, filtros)
        .then(({ data }) => (this.detalhesEquipamento = data))
        .catch((err) => {
          console.log('Erro: ', err);
          this.$toast.error(
            `Erro ao recuperar os dados do equipamento ${entidade}.`,
            '',
            { position: 'topRight' }
          );
        })
        .finally(() => (this.loadingDetalhesEquipamento = false));
    },
    configuraMapa(zoom) {
      this.map = L.map('mapa-beacon', {
        fullscreenControl: true,
        layers: [mapboxConfig.getDefaultLayer()],
        zoom
      });
      L.control.layers(mapboxConfig.getBaseLayers()).addTo(this.map);
      this.createBrowserPrintControl(L, 'Beacon').addTo(this.map);
      this.map.setView([-16.6974198, -49.2334694]);
    },
    plotaSubestacao() {
      const { poligono_subestacao, codigo_subestacao, nome_subestacao } =
        this.detalhesBeacon;
      const layer = L.geoJSON(JSON.parse(poligono_subestacao), {
        style: () => ({
          color: this.bdgdVersao.cor ?? '#9C27B0',
          weight: 1
        })
      });
      const msg = `<b>Subestação:</b> ${codigo_subestacao} - ${nome_subestacao}`;
      layer.bindPopup(msg).addTo(this.map);
      this.map.fitBounds(layer.getBounds());
    },
    plotaBeacon() {
      const { localizacao_beacon, entidade, cod_id, mac_address_beacon } =
        this.detalhesBeacon;
      const layer = L.geoJSON(JSON.parse(localizacao_beacon), {
        pointToLayer: (feature, latlng) => {
          return L.marker(latlng, {
            icon: L.icon.glyph({
              iconMDI: 'mdi-map-marker'
            })
          });
        }
      });
      const msg = `
<b>MAC Address:</b> ${mac_address_beacon}<br />
<b>Equipamento:</b> ${entidade}<br />
<b>COD_ID:</b> ${cod_id}
`;
      layer.bindPopup(msg).addTo(this.map);
      this.map.fitBounds(layer.getBounds());
    },
    visualizarMapa(latitude, longitude) {
      this.map.setView([latitude, longitude], 15);
    }
  },
  watch: {
    detalhesBeacon() {
      this.configuraMapa(4);
      this.plotaSubestacao();
      this.plotaBeacon();
    }
  }
};
</script>

<style>
#mapa-beacon {
  min-height: 600px;
  z-index: 0;
}
</style>
